.home_bg{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
      z-index:-1;
  display:block;

}
.home_bg_maincont{
    position: absolute;
    top: 25%;
    padding-left: 10vw;
}
.home_bg_head{
    font-size: 4.5rem !important;
    font-weight: 600 !important;
    color: white;
    padding-bottom: 2vh;

}
.home_bg_text{
font-size: 2rem !important;
    font-weight: 400 !important;
    color: white;
    padding-bottom: 2vh;
}
.home_bg_app{
    height: 50px;
    cursor: pointer;
}
.home_bg_paper{
        min-height: 50vh !important;
    max-width: 305px;
    border-radius: 20px !important;
}
.home_bg_inner{
        padding: 30px;

}
.home_bg_ppr_head{
font-size: 2rem !important;
    padding-bottom: 2vh;
}
.home_bg_ppr_text{
    font-size: 1rem !important;
    padding-bottom: 1.5vh;
}
.home_bg_ppr_works{
    font-size: 1.1rem !important;
    text-decoration-line: underline;
    padding-bottom: 1vh;
    text-decoration-color: var(--green);
    font-weight: 600 !important;
    cursor: pointer;
}
.home_bg_btn{
width: 60%;
    background: var(--green) !important;
    color: white !important;
    font-size: 16px !important;
}

.pickgradient {
  position:absolute;
  width:100%; height:100%;
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.65));}



  @media (max-width:500px) {
    .home_bg_head {
        font-size: 2rem !important;
        padding-bottom: 4vh;
        padding-top: 2vh;
    }
    .home_bg_text{
        font-size: 1.2rem !important;
        padding-bottom: 5vh;
    }
    .home_bg_paper {
      display: none;
    }
    .home_bg{
        height: 100vh;
        width: 100%;
        object-fit: cover;
        position: absolute;
          z-index:-1;
      display:block;
    
    }
    .pickgradient {
        /* position:absolute; */
        width:100%; height:100vh;
          background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.65));}
    
  }  