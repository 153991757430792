

.slider-container{
    padding :2vh 0px
}
.img_crousel{
height: 200px;
border-radius: 20px;
    border: 2px solid lightgray;
    padding: 5px;
    width: 200px;
    object-fit: contain;
}
.slick-prev:before, .slick-next:before {
color: #CC0000 !important;
font-size: 35px !important;
}

.slider-container .slick-slide{
    width: 300px !important;
}



.slider-container .slick-list {
    
    /* padding: 0px 200px; */
    margin-left: 10px;
}
@media (max-width:500px) {
    .slider-container .slick-slide{
    width: 305px !important;
}
    .slider-container .slick-list {
    
        /* padding: 0px 200px; */
        margin-left: 0px !important;
        /* text-align: center; */
        /* padding-left:20px !important; */
    }
    .slick-prev:before, .slick-next:before {
        display: none !important;
        }
}
